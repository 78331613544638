import { Injectable } from '@angular/core';
import { IToaster } from '../interfaces';
import { TOASTER_TYPE } from '../constants';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
    
  private subject: BehaviorSubject<IToaster> = new BehaviorSubject<IToaster>(null);;
  toast$: Observable<IToaster> = this.subject.asObservable().pipe(filter(toast => toast !== null));

  show(type: TOASTER_TYPE, title?: string, body?: any, delay?: number) {
    this.subject.next({ type, title, body, delay });
  }
}