import { MONTH_YEAR_LABEL, MONTH_YEAR_A11Y_LABEL, DATE_A11Y_LABEL, MAT_MONTH_FORMAT } from 'src/app/shared/constants';

export class CustomMonthFormat {
  
    get display(){
      return {
        dateInput: MAT_MONTH_FORMAT,
        monthYearLabel: MONTH_YEAR_LABEL,
        dateA11yLabel: DATE_A11Y_LABEL,
        monthYearA11yLabel: MONTH_YEAR_A11Y_LABEL,
      }
    }
  
    get parse(){
      return { dateInput: MAT_MONTH_FORMAT}
    }
}