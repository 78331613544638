import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { 
  AuthGuard, 
  OrgGuard 
} from './shared/auth';

const routes: Routes = [ 
 
  {
    path : ':orgUrl',
    children : [
      {
        path: "signon",
        loadChildren: () => import("./modules/sign-on/auth.module").then((m) => m.AuthModule),
      },
      {
        path: "home",
        loadChildren: () => import("./modules/home/home.module").then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: "configure",
        loadChildren: () => import("./modules/maintenance/maintenance.module").then((m) => m.MaintenanceModule),
        canActivate: [AuthGuard],
      },{
        path: "report",
        loadChildren: () => import("./modules/maintenance/maintenance.module").then((m) => m.MaintenanceModule),
        canActivate: [AuthGuard],
      },
      {
        path: "assist",
        loadChildren: () =>
          import("./modules/assist-host/assist-host.module").then((m) => m.AssistHostModule),
        canActivate: [AuthGuard],
      },
      { path: '', redirectTo: '/assist', pathMatch: 'full' },
      { path: '**', redirectTo: '/home', pathMatch: 'full' }
    ],
    canActivate:[OrgGuard],
    data : {ngRoutes:["configure"]}
  },

  { path: '', redirectTo: '/signon', pathMatch: 'full'},
  { path: '**', redirectTo: '', pathMatch: 'full' }      
];
 
@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
