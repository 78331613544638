import { Injectable } from '@angular/core';
import {
  MatDateRangeSelectionStrategy,
  DateRange,
} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateTimeService } from 'src/app/shared/services';

@Injectable()
export class WeekSelectionStrategy<D>
  implements MatDateRangeSelectionStrategy<D>
{
  constructor(private dateAdapter: DateAdapter<D>) {}

  selectionFinished(date: D | null): DateRange<D> {
    return this.createWeekRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this.createWeekRange(activeDate);
  }

  createDAFormat(date: Date): D{
    return this.dateAdapter.createDate(date.getFullYear(), date.getMonth(), date.getDate());
  }

  private createWeekRange(date: D | null): DateRange<D> {
    if (date) {
      const dateService = new DateTimeService();
      const dateParam = dateService.customWeek(date.toString());
      let start: D = this.createDAFormat(dateParam?.startDate);
      let end: D = this.createDAFormat(dateParam?.endDate);
      return new DateRange<D>(start, end);
    }
    return new DateRange<D>(null, null);
  }
}
