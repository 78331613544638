import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'carina-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {


  isChecked : number;

  constructor() { }

  ngOnInit(): void {

  }

}
