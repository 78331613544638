import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from '../material/material.module';
import {
  ControlHostComponent,
  HeaderButtonComponent,
  HeaderSelectComponent,
  SelectDialogComponent,
  ModifyColumnDialogComponent,
  DateControlComponent,
  HeaderComponent,
  SwitchComponent,
  DataTableComponent,
  ScrollToTopComponent,
  GridViewComponent,
  DragAndDropDialogComponent
} from './components';

import { 
  ControlsDirective, 
  DoubleClickDirective,
  TableColumnEditDirective
} from './directives';
import { FnPipe } from './pipes/fn.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    ControlHostComponent,
    HeaderButtonComponent,
    HeaderSelectComponent,
    SelectDialogComponent,
    ModifyColumnDialogComponent,
    DateControlComponent,
    HeaderComponent,
    DataTableComponent,
    ControlsDirective,
    DoubleClickDirective,
    TableColumnEditDirective,
    SwitchComponent,
    ScrollToTopComponent,
    GridViewComponent,
    FnPipe,
    DragAndDropDialogComponent
  ],
  exports: [
    ControlHostComponent,
    HeaderButtonComponent,
    HeaderSelectComponent,
    SelectDialogComponent,
    DateControlComponent,
    ModifyColumnDialogComponent,
    HeaderComponent,
    DataTableComponent,
    ControlsDirective,
    DoubleClickDirective,
    SwitchComponent,
    ScrollToTopComponent,
    GridViewComponent,
    FnPipe,
    DragAndDropDialogComponent
  ],
  imports: [ 
    CommonModule,
    FormsModule,
    MaterialModule,
    DragDropModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {
  constructor() {}
}
