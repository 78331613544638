import { Injectable } from '@angular/core';
import { DateFormatPipe } from "src/app/shared/pipes/date-format.pipe";
import { DEFAULT_DATE_FORMAT, DEFAULT_MONTH_FORMAT, DEFAULT_MONTH_LABEL, DEFAULT_YEAR_LABEL, DEFAULT_DATE_LABEL, MAT_DATE_LABEL } from "src/app/shared/constants";

@Injectable({
  providedIn: 'root'
})

export class DateTimeService {

    public datePipe;
    public defFormat: string = DEFAULT_DATE_FORMAT;

    constructor(){
        this.datePipe = new DateFormatPipe();
    }

    newDate(): Date{
        return new Date();
    }

    currentDateTime(): Date{
        return this.UTCToLocal(this.newDate());
    }

    UTCToLocal(date: Date): Date{
        let convDate = this.datePipe.transform(date, this.defFormat);
        return new Date(new Date(`${convDate.replace(' ','T')}Z`).toString());
    }

    currentUnixDate(): number{     
        return this.toUnixTime(this.currentDateTime()); 
    }

    toUnixTime(date: Date){    
        return (date).getTime() / 1000; 
    }

    convertUnixToDate(value: string): Date{ 
        const timestamp: number = Number(value);
        return new Date(timestamp * 1000);
    }

    convertUnixToDateWithFormat(value: string){ 
        return this.datePipe.transform(this.convertUnixToDate(value));
    }

    convertUnixToDatem(value: any): any{ 
        const timestamp: number = Number(value/1000);
        return new Date(timestamp * 1000);
    }
    
    convertUnixToDateWithFormatm(value: string){ 
        return this.datePipe.transform(this.convertUnixToDatem(value));
    }

    currentMonth(): Date{   
        return this.startOfMonth(this.newDate());
    }

    startOfMonth(date: Date): Date{     
        return this.UTCToLocal(new Date(date.getFullYear(), date.getMonth(), 1)); 
    }

    transformMonth(date: any){  
        if(date && date != null){
            return this.datePipe.transform(this.dateObject(date, this.datePipe.pattern), DEFAULT_MONTH_FORMAT); 
        }
        return date;
    }

    currentWeek(){     
        return this.customWeek(this.currentDateTime());
    }

    customWeek(date: any){
        date = new Date(date);
        let maxDate = this.currentDateTime();
        let first = date.getDate() - date.getDay() + (date.getDay() === 6 ? 6 : -1); 
        let firstDate = new Date(date.setDate(first));   
        let lastDate = new Date(date.setDate(date.getDate()+6));
        lastDate = (lastDate > maxDate) ? maxDate : lastDate;
        return {
            startDate: firstDate,
            endDate: lastDate
        }
    }

    addDays(date: Date, value: number): Date{  
        return this.UTCToLocal(new Date(date.setDate(date.getDate() + value)));
    }

    minusDays(date: any, value: number): Date{     
        return this.UTCToLocal(new Date(date.setDate(date.getDate() - value)));
    }

    addMonth(date: Date, value: number): Date{  
        return this.startOfMonth(new Date(date.setMonth(date.getMonth() + value)));
    }

    minusMonth(date: any, value: number): Date{     
        return this.startOfMonth(new Date(date.setMonth(date.getMonth() - value)));
    }
    
    defaultDateType(value: string): Date{ 
        const parts = value.split('/');
        return new Date(+parts[2], +parts[1] - 1, +parts[0]);
    }

    dateObject(dateString: string, pattern?: string): Date{
        let patterns: any = pattern.split('/');
        let datetimeValue: any = dateString.split('/');
        let datePattern = [DEFAULT_DATE_LABEL, MAT_DATE_LABEL];
        let monthPattern = [DEFAULT_MONTH_LABEL];
        let yearPattern = [DEFAULT_YEAR_LABEL];
        let dateValue; 
        let monthValue; 
        let yearValue; 

        datetimeValue.forEach((element, index) => {
            if(datePattern.indexOf(patterns[index]) !== -1){
                dateValue = element;
            }
            if(monthPattern.indexOf(patterns[index]) !== -1){
                monthValue = element;
            }
            if(yearPattern.indexOf(patterns[index]) !== -1){
                yearValue = element;
            }
        });

        return new Date(+yearValue, +monthValue - 1, +dateValue)
    }

}
