import { Component, OnInit } from '@angular/core';
import { AppService, AppMenuService } from '../../services'
import { HeaderButton } from '../../classes/header-button';
import { IControlComponent } from '../../interfaces/icontrol-component';
import { HeaderSelect } from '../../classes/header-select';

@Component({
  selector: 'carina-header-select',
  templateUrl: './header-select.component.html',
  styleUrls: []
})
export class HeaderSelectComponent implements IControlComponent {

  controlData: HeaderSelect;

  constructor(
    private appService: AppService,
    private menuService: AppMenuService
  ) {

  }

  onClick(control): void { 
    if(control.method == 'func'){
      this.menuService[control.action]();
    }else{
      this.appService.headerButtonPress(control);
    }
  }

}
 