import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { FuncParam } from 'src/app/shared/enums';

@Injectable({
  providedIn: 'root'
})

export class AppMenuService {

  private _reloadReport: Subject<string> = new Subject<string>();
  public readonly reloadReport: Observable<string> = this._reloadReport.asObservable();

  private _exportReport: Subject<string> = new Subject<string>();
  public readonly exportReport: Observable<string> = this._exportReport.asObservable();

  private _editReport: Subject<string> = new Subject<string>();
  public readonly editReport: Observable<string> = this._editReport.asObservable();

  private _copyNewItem: Subject<string> = new Subject<string>();
  public readonly copyNewItem: Observable<string> = this._copyNewItem.asObservable();

  private _showEditForm: Subject<string> = new Subject<string>();
  public readonly showEditForm: Observable<string> = this._showEditForm.asObservable();

  private _addNewItem: Subject<string> = new Subject<string>();
  public readonly addNewItem: Observable<string> = this._addNewItem.asObservable();

  initExportCSV(){
    this._exportReport.next(FuncParam.CSV); 
  }
  
  initExportSPR(){
    this._exportReport.next(FuncParam.SPR); 
  } 
  
  initReset(){
    this._reloadReport.next(FuncParam.RESET); 
  }
  
  initEditReport(){
    this._editReport.next(FuncParam.EDIT); 
  }

  initEditForm(){
    this._showEditForm.next(FuncParam.EDIT); 
  }
  
  initCopyNew(){
    this._copyNewItem.next(FuncParam.COPY); 
  }

  initCancel(){
    this._editReport.next(FuncParam.CANCEL); 
  }

  initSubmitReport(){
    console.log('Init: Submit report');
  }

  initAddNew(){
    this._addNewItem.next(FuncParam.ADD); 
  }

  initSave(){
    this._editReport.next(FuncParam.SAVE); 
  }
}