<div class="dialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" > 
    <h1 mat-dialog-title cdkDragHandle>Select {{ dialogTitle }}</h1>
</div>
<div mat-dialog-content class="container pdy-0">
    <div class="form-input shared-box">
        <label>Available {{ dialogTitle }}:</label>
        <select multiple="true" [formControl]="filterOptsModel" class="selMultiSelect">
            <option *ngFor="let option of filterOpts" [value]="option.val" (doubleClick)="onDoubleClick(multiselectOpt.ADD)">
                <span *ngIf="option.itms">⁂</span> {{option.txt}}
            </option>
        </select>
    </div>
    <div class="shared-box select-tool">
        <button mat-mini-fab color="primary" matTooltip="Select items" [matTooltipShowDelay]="2000" (click)="toggleOption(multiselectOpt.ADD)">
            <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button mat-mini-fab color="primary" matTooltip="Discard items" [matTooltipShowDelay]="2000" (click)="toggleOption(multiselectOpt.REMOVE)">
            <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
        <button mat-mini-fab color="primary" matTooltip="Select ⁂ All" [matTooltipShowDelay]="2000" (click)="toggleOption(multiselectOpt.CLEAR)">
            <mat-icon>arrow_back</mat-icon>
        </button>
        <button mat-mini-fab color="primary" matTooltip="Discard All" [matTooltipShowDelay]="2000" (click)="toggleOption(multiselectOpt.RESET)">
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
    <div class="form-input shared-box">
        <label>Selected {{ dialogTitle }}:</label>
        <select multiple="true" [formControl]="selectedOptsModel" class="selMultiSelect">
            <option *ngFor="let option of selectedOpts" [value]="option.val" (doubleClick)="onDoubleClick('remove')">
                <span *ngIf="option.itms">⁂</span> {{option.txt}}
            </option>
        </select>
    </div>
</div>
<div mat-dialog-actions>
    <div class="select-search">
        <mat-label>Filter: &nbsp;</mat-label>
        <input matInput type="search" [ngModel]="searchText" (ngModelChange)="searchByKey($event)">
    </div>
    <div class="dialog-action-btn">
        <button mat-flat-button mat-dialog-close>Cancel</button>
        <button mat-flat-button [disabled]="disableBtn" color="primary" (click)="refreshData()">Ok</button>
    </div>
</div>
