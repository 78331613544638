<!-- <h1 mat-dialog-title>{{ dialogTitle }}</h1> -->
<div  cdkDropListGroup class="mat-mdc-dialog-content mdc-dialog__content container pdy-0">
    <div class="chip-container">
        <h2>Selected:</h2>
        <div
            id="selected-list"
            #selectedList
            class="chip-list"
            [ngStyle]="{ '--box-width': boxWidth, '--box-height': boxHeight }">
            <div
                cdkDropList
                (cdkDropListEntered)="onDropListEntered($event)"
                (cdkDropListDropped)="dropSelected($event)">
            </div>
            <div
                cdkDropList
                class="selected-item"
                (cdkDropListEntered)="onDropListEntered($event)"
                (cdkDropListDropped)="dropSelected($event)"
                *ngFor="let item of arrStatsValue">
                <div class="chip-box" cdkDrag (cdkDragMoved)="onDraggingSelectedList($event)">
                    <span class="isMoving"></span>
                    {{item.txt}}
                </div>
            </div>
        </div>
    </div>
    
    <div class="select-search">
        <mat-label class="label">Filter: &nbsp;</mat-label>
        <input matInput type="search" [(ngModel)]="searchText" (ngModelChange)="correntDisplayItems()">
    </div>

    <div class="chip-container">
        <h2>Linked:</h2>
        <div
            id="linked-list"
            class="chip-list"
            [ngStyle]="{ '--box-width': boxWidth, '--box-height': boxHeight }">
            <div
                cdkDropList
                class="linked-item"
                (cdkDropListDropped)="dropSelected($event)"
                *ngFor="let item of arrLinkedOpts; let i = index;">
                <!-- *ngFor="let item of arrStatsValue" -->
                <div *ngIf="item" class="chip-box linked" cdkDrag (cdkDragMoved)="onDraggingNonSelectedList($event, i)">
                    <span class="isAdding"></span>
                    {{item.txt}}
                </div>
            </div>
        </div>
    </div>
    
    <div class="chip-container">
        <h2>Unlinked:</h2>
        <div
            id="unlinked-list"
            class="chip-list"
            [ngStyle]="{ '--box-width': boxWidth, '--box-height': boxHeight }">
            <div
                cdkDropList
                class="unlinked-item"
                (cdkDropListDropped)="dropSelected($event)"
                *ngFor="let item of arrUnlinkedOpts; let i = index;">
                <div class="chip-box linked" cdkDrag (cdkDragMoved)="onDraggingNonSelectedList($event, i)">
                    <span class="isAdding"></span>
                    {{item.txt}}
                </div>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">  
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button (click)="save()" mat-flat-button color="primary" color="primary" >Save</button>
</div>
