import { EnumHeaderControlType } from "../enums/enum-header-control-type";
import { ListEntry } from "../interfaces/list-entry";
import { HeaderControl } from "./header-control";
import { IAppMenuButton, IAppMenuSelect } from '../interfaces';

export class HeaderSelect extends HeaderControl {
  public id :string;
  public name : string;
  public title : string;
  public disabled : boolean;
  public opts : IAppMenuButton[];

  constructor(menu: IAppMenuSelect) {

    super(EnumHeaderControlType.HeaderSelect, menu.id, menu.name);


    this.id = menu.id;
    this.name = menu.name;
    this.title = menu.title;
    this.disabled = menu.disabled;
    this.opts = menu.opts;
  }

}
