import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, SimpleChanges  } from '@angular/core';
import { ReportData } from 'src/app/modules/report/claases';
import { formatDate } from '@angular/common';
import { of,timer } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DEFAULT_APP_WIDTH, COLUMN_MENU, COLUMN_ATTRIBUTE } from 'src/app/shared/constants';
import { IAppMenuButton, IReportColumn } from 'src/app/shared/interfaces';
import { ReportColumn } from 'src/app/shared/classes';
import { MatDialog } from '@angular/material/dialog';
import { ModifyColumnDialogComponent } from '../';
import { MatMenuTrigger } from '@angular/material/menu';
import {
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MaintDataService } from 'src/app/modules/maintenance/services/maint-data.service';
import { AppMenuService } from 'src/app/shared/services';
import { FuncParam } from 'src/app/shared/enums';

@Component({
  selector: 'carina-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, OnDestroy {

  groupingOff : boolean = false;  // No grouping for CSV export
  isGrouping  : boolean = false;
  iGroupIndex : number = 0;
  arrGroup : any [];
  formatOptions : any;
  showEmptyMessage: boolean = false;
  isLoading: boolean = true;
  columnMenu: IAppMenuButton[] = COLUMN_MENU;
  columnConfig: ReportColumn;
  newColumn: any = COLUMN_ATTRIBUTE;
  newColumnIndex: number = 1;
  pasteIndex: number;
  forwardIndex: number;
  backwardIndex: number;
  private _subscriptions : Subscription[] = [];
  successMessage: string;
  private subscription: Subscription;
  display:any = " ";


  @Input() reportData : ReportData;
  @Output() triggerTableClass = new EventEmitter<boolean>();
  @Output() updateReport = new EventEmitter<number>();
  @Output() updateReportConfig = new EventEmitter<IReportColumn>();
  @Input() enabledEditReport: boolean;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private maintData: MaintDataService,
    private menuService: AppMenuService
  ) {
  
  }

  ngOnInit() {
    this.reportData.values = [];
    if(history.state.user!="no" && history.state.user!=null ){
    this.successMessage = history.state.user;
    const delayTime = 3000;
    timer(delayTime).subscribe(() => {
      this.successMessage = null;
    });
    }
    this._subscriptions.push(
      this.menuService.addNewItem.subscribe(response => {
        if(response && response == FuncParam.ADD){
          this.redirectToMode('edit', 0, 0);
        }
      })
    );
     this._subscriptions.push(
      this.menuService.copyNewItem.subscribe(response => {
        if(response && response == FuncParam.ADD){
          this.redirectToMode('copy', 0, 0);
        }
      })
    );
    this.formatOptions = this.reportData.formatOptions;
    this.toggleLoaderAndErrMessage(); 
    this.columnMenu.forEach((elem, index) => {
      if(elem.name ==='paste'){
        this.pasteIndex = index;
      }     
      if(elem.name ==='forward'){
        this.forwardIndex = index;
      }      
      if(elem.name ==='backward'){
        this.backwardIndex = index;
      }
    });
  }

  onOpenMenu(columnName: string) {
    let columnIndex = this.reportData.showColumns.indexOf(columnName);
    this.columnMenu[this.pasteIndex].disabled = true;
    this.columnMenu[this.forwardIndex].disabled = true;
    this.columnMenu[this.backwardIndex].disabled = true;
    if(this.columnConfig && this.columnConfig != null){
      this.columnMenu[this.pasteIndex].disabled = false;
    }
    if(this.reportData.showColumns[columnIndex+1]){
      this.columnMenu[this.forwardIndex].disabled = false; 
    }
    if(this.reportData.showColumns[columnIndex-1]){
      this.columnMenu[this.backwardIndex].disabled = false; 
    }
  }

  toggleLoaderAndErrMessage(): void{
    this.isLoading = true;
    this.reportData.httpRequest.subscribe(data => {
      this.isLoading = false;
     }, error => this.isLoading = false);
    
    of(this.reportData.values).pipe(delay(1000))
     .subscribe(data => {
       this.showEmptyMessage = true;
       this.checkTableWidth();
     });
  }

  checkTableWidth(){
    let isWide: boolean = false;
    let tableWidth: any = Number(this.reportData.tableStyle?.maxWidth.replace(/\D/g, ""));
    if(tableWidth && tableWidth > DEFAULT_APP_WIDTH){
      isWide = true;
    } 
    this.triggerTableClass.emit(isWide);
  }

  setPagination(page): void{
    this.updateReport.next(page.pageIndex);
  }

  public formatCell = (input: any, rowIndex: number, colIndex: number) => {

    var params = [];
    var tagsToReplace = {
     '&': '&amp;',
     '<': '&lt;',
     '>': '&gt;'
    };

    function replaceTag(tag) {
     return tagsToReplace[tag] || tag;
    }
    
    // Rest grouping for first column
    if (!this.groupingOff && colIndex === 1 ) {
      this.isGrouping = true;
      this.iGroupIndex = 0;
      if (rowIndex == 0) {
        this.arrGroup = [];
      }
    }
       
    if (input === undefined || input === null) {
      input = '';
    }
   
    var column = this.reportData.columns[colIndex];
    
    if (column.bIsLink) {
      var cell = JSON.parse(input);
      if (cell) {
        input = cell.v;
        if (Array.isArray(cell.p)) {
          params = cell.p;
        }
      }
    }

    var result = input;
    
    switch (column.strFormatType) {
      case 'DATE':
        result = isNaN(input) ? input.substr(0, 100) : this.formatDate(input*1000, column.iFormatValue);
      break;  
      case 'TIME':
        result = this.formatTime(input, column.iFormatValue, false);//(this.target === 'XLS' || this.target === 'CSV'));
      break;  
      case 'STRG':
        result = input.substr(0, column.iFormatValue);
        
        result =  result.replace(/[&<>]/g, replaceTag);      
      break;  
      case 'FLOT':
        if (input != '') {
          result = parseFloat(input).toFixed(column.iFormatValue);
          if (result === 0.0) {
            result = '';
          }
        }
      break;
      case 'INTG':
        if (input != '') {
          result = parseInt(input);
          if (result === 0) {
            result = '';
          }
        }
      break;
      case 'ICON':
        switch (column.iFormatValue) {
          case 1:
            result = "rowEdit";
          break;  
        }
        
      break;
    }
    
    if (column.bIsLink) {
      
      var link = column.strLink;
      
      var count = 0;
      params.forEach(function(strParam) {
        link = link.replace('{'+count+'}', strParam);
        count++;
      });
      
      if (column.strFormatType === 'ICON') {
        result = '<a href="' + link + '"><img id="cell_'+rowIndex+'_'+colIndex+'" class="imgButton" src="images/noImage.png" name="'+result+'" width="16px" height="16px;"></a>';
      } else {
        result = '<a href="' + link + '" target="myReport">'+result+'</a>';
      }
    }

    if (!this.groupingOff) {
      var bGrouped = false;
      if (column.bGroup) {
        if (this.isGrouping) {
          if (result === this.arrGroup[this.iGroupIndex]) {
            result = '';
            bGrouped = true;
          } else {
            this.isGrouping = false;
          }
          this.iGroupIndex++;
        }
        if (!bGrouped) {
          this.arrGroup[colIndex-1] = result;
        }
      } else { 
        this.isGrouping = false;      
      }
    }
    
    return result;

  }

  public formatDate(input : number, format, offset = undefined, unit = undefined) {

      let formatOptions = this.formatOptions;

      var fmt = (format === undefined) ? 'shortDate': format;
  
      if (typeof fmt === "string") {
        fmt = formatOptions.datePresets.hasOwnProperty(fmt) ? formatOptions.dateFormats[this.formatOptions.datePresets[fmt]].fmt : fmt; 
      }  
  
      if (typeof fmt === "number") {
        if (fmt < 0 && fmt >= formatOptions.maxDateIndex) {
          fmt = 0;
        }
        fmt = formatOptions.dateFormats[fmt].fmt;
      }
     
      if (input !== undefined &&
          input > formatOptions.minDate &&    
          offset !== undefined && unit !== undefined && 
          (typeof offset === 'number' && (offset%1) === 0 && offset !== 0) &&
          (unit === 'd' || unit === 'm' || unit === 'y')) {
  
        let dt = new Date(input);
        dt = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0,0,0)); // Make UTC Date
                
        if (dt.toString() !== 'Invalid Date') {
        
          if (unit === 'd') {
            dt.setUTCDate(dt.getUTCDate() + offset);
          } else if (unit === 'm') {
            var orgDay = dt.getUTCDate();
  
            if (orgDay <= 28) {
              dt.setUTCMonth(dt.getUTCMonth() + offset);
            } else {
              var newDay = 28;
              dt.setUTCDate(newDay);
              var month  = dt.getUTCMonth() + offset;
  
              dt.setUTCMonth(month);            
              dt.setUTCDate(newDay + 1);
  
              while (newDay < orgDay && dt.getUTCMonth() === month) {
                newDay = newDay + 1;
                dt.setUTCDate(newDay + 1);
              }
  
              dt.setUTCMonth(month);            
              dt.setUTCDate(newDay);        
            }
          } else {
            dt.setUTCFullYear(dt.getUTCFullYear() + offset);
          }
          return formatDate(dt, fmt, 'en-gb');
        } else {
          return formatDate(input, fmt, 'en-gb');
        }
      } else {
        if (input <= formatOptions.minDate) {
          return '';
        } else {
          return formatDate(input, fmt, 'en-gb');
        }
      }
  }

  public formatTime(input, format, isExport = false) {

    let formatOptions = this.formatOptions;

    if (input > 0) {
      var fmt = (format === undefined) ? 'default': format;

      if (typeof fmt === "string") {
        fmt = formatOptions.timePresets.hasOwnProperty(fmt) ? formatOptions.timeFormats[formatOptions.timePresets[fmt]].fmt : fmt; 
      }  

      if (typeof fmt === "number") {
        if (fmt < 0 && fmt >= formatOptions.maxTimeIndex) {
          fmt = 0;
        }

        if (isExport) {
          fmt = formatOptions.timeFormats[fmt].exp;
        }
       
        fmt = formatOptions.timeFormats[fmt].fmt;      
      }

      if (fmt.substring(0, 9) === 'duration:') {

        var mode = parseInt(fmt.substr(9, 1), 10);

        if (mode === 0) {
          var dur = input;
          var hrs = Math.floor(dur/3600);

          dur = dur - hrs*3600; 
          var min = Math.floor(dur/60);
          var sec = Math.round(dur - min*60);
          var m = '00' + min;
          var s = '00' + sec;

          return hrs+':'+m.substr(m.length - 2)+':'+s.substr(s.length - 2);
        } else if (mode === 1) {
          var dur = input;
          var min = Math.floor(dur/60);
          var sec = Math.round(dur - min*60);
          var m = min > 9 ? "" + min : '0' + min;
          var s = '00' + sec;
          
          return m +':'+s.substr(s.length - 2);
        } else {
          return ""+ Math.round(input);
        }

      } else {
        return formatDate(input * 1000, fmt, 'en-gb');
      }
    } else {
      return "";
    }
    
  }

  triggerMenuEvent(control: IAppMenuButton, column: any, columnIndex: number): void { 
    if(control.method == 'func'){ 
      let columnIndex = this.reportData.showColumns.indexOf(column.name);
      this[control.action](column, columnIndex);
    }
  }

  initColumnEdit(column: IReportColumn, columnIndex: number): void{
    const dialogRef = this.dialog.open(ModifyColumnDialogComponent, {
      data: {
        columnIndex: columnIndex,
        showColumns: this.reportData.showColumns,
        columns: this.reportData.columns
      },
      width: '760px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.updateReportConfig.next(result.data);
    });
  }

  initColumnCut(column: ReportColumn, columnIndex: number): void{
    this.columnConfig = column;
    this.reportData.showColumns = this.reportData.showColumns.filter((elem) => {
      return elem != column.name;
    });
    
    this.reportData.columns = this.reportData.columns.filter((elem) => {
      return elem.name != column.name;
    });
  }

  initColumnCopy(column: ReportColumn, columnIndex: number): void{
    this.columnConfig = new ReportColumn(column, this.reportData.showColumns.length+1); 
  }

  initColumnPaste(column: ReportColumn, columnIndex: number): void{
    columnIndex += 1;
    this.reportData.columns.push(this.columnConfig);
    this.reportData.showColumns = [
      ...this.reportData.showColumns.slice(0, columnIndex),
      this.columnConfig.name,
      ...this.reportData.showColumns.slice(columnIndex)
    ];
    this.columnConfig = null;
  }

  initColumnAdd(column: ReportColumn, columnIndex: number): void{
    let newColumn = new ReportColumn(this.newColumn, this.reportData.showColumns.length+1);
    columnIndex += 1;
    this.reportData.columns.push(newColumn);
    this.reportData.showColumns = [
      ...this.reportData.showColumns.slice(0, columnIndex),
      newColumn.name,
      ...this.reportData.showColumns.slice(columnIndex)
    ];
  }

  initColumnForward(column: ReportColumn, columnIndex: number): void {
    this.toggleOrder(columnIndex, 1);
  }

  initColumnBackward(column: ReportColumn, columnIndex: number): void {
    this.toggleOrder(columnIndex, -1);
  }

  toggleOrder(columnIndex: number, value): void{
    let altIndex = columnIndex+value;
    let temp = this.reportData.showColumns[columnIndex];
    this.reportData.showColumns[columnIndex] = this.reportData.showColumns[altIndex];
    this.reportData.showColumns[altIndex] = temp;
  }

  startMaint(row: number, column : number) {
    let rowData = this.reportData.values[row];
    let cellData = rowData[column];
    let mode = cellData.mode.toLowerCase();
    let id = 0; 
    switch (mode) {
      case "edit":
        id = cellData.params?.id || cellData.params?.library_id || cellData.params?.document_group_id || cellData.params?.docId;
      break;  
      case "add":
        id = 0;
      break; 
      case "del":
        id = cellData.params.delete_id;
      break;              
    }
    this.redirectToMode(mode, id, row);
  }

  redirectToMode(mode, id, row){
    let reportId = parseInt(this.route.snapshot.paramMap.get("reportId"));//For "Task Entries" remove later
    let options = this.reportData.options;
    if (options.editInline || reportId == -16 || reportId == -7) {
      this.router.navigate([{ outlets: { auxiliary: [mode, id] } }], { relativeTo: this.route, state : { row: row }});
    } else {
      this.router.navigate([mode, id], {relativeTo:this.route});
    }
  }

  goto(){
    this.router.navigate(['edit', 15271], {relativeTo:this.route});
  }

  ngOnDestroy(): void { 
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
