<div class="tool-group">
    <mat-icon 
        matTooltip="Select items" 
        (click)="updateDate(dateController.PREV); $event.stopPropagation();" 
        [matTooltipShowDelay]="2000">
        keyboard_arrow_left
    </mat-icon>
    <span [class.disabled]="disableBtn" (click)="updateDate(dateController.TODAY)">Today</span>
    <mat-icon 
        [class.disabled]="disableBtn" 
        matTooltip="Select items" 
        (click)="updateDate(dateController.NEXT); $event.stopPropagation();" 
        [matTooltipShowDelay]="2000">
        keyboard_arrow_right
    </mat-icon>
</div>