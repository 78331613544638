export const LOCATION_FORM_PARAM: object[] = [
    {
        title: "Users", 
        id: 'arrOperators'
    }, { 
        title: "Journal Groups", 
        id: 'arrGroups'
    }
];

export const LOCATION_CONFIG_PARAM = {
    arrOperators: [],
    arrGroups: [],
};
