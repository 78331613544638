
import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MultiselectController } from 'src/app/shared/enums';
@Component({
  selector: 'carina-dialog-select',
  templateUrl: 'dialog-select.component.html',
  styleUrls: ['dialog-select.component.scss']
})
export class SelectDialogComponent implements OnInit {

  filterOptsBackup: any;
  filterOptsBackupOnlyVal: any = [];
  filterOpts: any = [];
  selectedOpts: any = [];
  selectedOptsModel = new FormControl(''); ;
  filterOptsModel = new FormControl(''); ;
  searchText: string;
  disableBtn: boolean;
  dialogTitle: string;
  multiselectOpt = MultiselectController;
  isRequired: boolean;

  constructor(
    public dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.filterOptsBackup = this.data.filterOpts;
    this.dialogTitle = this.data.title;
    this.isRequired = this.data?.isRequired;
    this.itemsMap(this.data?.selectedOpts);
  }

  ngOnInit(): void {
      for(let opts of this.filterOptsBackup){
        this.filterOptsBackupOnlyVal.push(opts.val);
      }
  }

  onDoubleClick(type) {
    this.toggleOption(type);
  }

  optionSort(){
    this.filterOpts.sort((a,b) =>  a.itms ? -1 : (b.itms ? 1 : a.txt.localeCompare(b.txt)));
  }

  itemsMap(selectedOptions){
    if(this.filterOptsBackup?.length > 0 && selectedOptions != null){
      this.selectedOpts = [];
      this.filterOpts = [];
      this.filterOptsBackup.forEach((elm: any) => {
        if(selectedOptions?.length > 0 && selectedOptions.indexOf(elm.val) !== -1){
          this.selectedOpts.push(elm);
        }else{
          this.filterOpts.push(elm);
        }
      });
    }
  }

  searchByKey(data: string) {
    this.searchText = data;
    this.search();
  }

  search() {
    let tempOptions = this.filterOptsBackup;
    let optsModel = this.selectedOpts.map(elem => elem.val);
    this.filterOpts = tempOptions.filter((element) => {
      if(this.searchText === ""){
        return optsModel.indexOf(element.val) == -1;
      }else{
        return element.txt.toLowerCase().includes(this.searchText.toLowerCase()) && optsModel.indexOf(element.val) == -1;
      }
    });
  }

  toggleOption(type: string){
    let selectedValue, filterValue;
    switch(type){
      case this.multiselectOpt.ADD:
        selectedValue = this.filterOptsModel.getRawValue();
        if(selectedValue?.length > 0 && selectedValue != null){
          filterValue = this.selectedOpts.map(a => a.val);
          filterValue = [...filterValue, ...selectedValue];
        }
        this.itemsMap(filterValue);
      break;
      case this.multiselectOpt.REMOVE:
        selectedValue = this.selectedOptsModel.getRawValue();
        if(selectedValue?.length > 0 && selectedValue != null){
          filterValue = this.selectedOpts.filter((a) => {
            if(selectedValue.indexOf(a.val) == -1){
              if(!a.val) return '0';
              return a.val;
            }
          }).map(a => a.val);
        }
        this.itemsMap(filterValue);
      break;
      case this.multiselectOpt.CLEAR:
        this.filterOpts = [...this.filterOpts, ...this.selectedOpts];
        this.selectedOpts = [];
      break;
      case this.multiselectOpt.RESET:
        let defValue = (this.filterOptsBackup.some(row => row.txt.toLowerCase() === 'all')) ? [0] : this.filterOptsBackupOnlyVal;
        this.itemsMap(defValue);
      break;
    }
    this.updateForm();
  }
  
  updateForm(){
    this.optionSort();
    this.selectedOptsModel.reset();
    this.filterOptsModel.reset();
    if(this.isRequired){
      this.disableBtn = (this.selectedOpts?.length > 0) ? false : true;
    }
  }
  
  refreshData(){
    this.dialogRef.close({ data: this.selectedOpts, saved: true });
  }
}