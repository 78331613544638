export enum FieldType {
    CKBX = 'CKBX',
    DTDY = 'DTDY',
    DTDS = 'DTDS',
    DTMN = 'DTMN',
    DTRG = 'DTRG',
    DTRM = 'DTRM',
    DTWK = 'DTWK',
    MTSL = 'MTSL',
    MTGP = 'MTGP',
    NUMB = 'NUMB',
    STRG = 'STRG',
    DDL  = 'DDL',
}

