import { Component } from '@angular/core';
import { AppService } from 'src/app/shared/services/app.service';
import { APP_TITLE, DEFAULT_LAYOUT, WIDE_LAYOUT, DEFAULT_LAYOUT_CLASS, WIDE_LAYOUT_CLASS } from 'src/app/shared/constants';

@Component({
  selector: 'carina-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title: string = APP_TITLE;
  strLayout: string = DEFAULT_LAYOUT;
  strClass: string = DEFAULT_LAYOUT_CLASS;

  constructor(
    private appService: AppService
  ) { 
    this.setLayout();
  }

  setLayout(){
    this.appService.pageLayout.subscribe(strLayout => {
      this.strLayout = strLayout; 
      if (this.strLayout == WIDE_LAYOUT) {
        this.strClass = WIDE_LAYOUT_CLASS;
      } else {
        this.strClass = DEFAULT_LAYOUT_CLASS;
      }
    });  
  }
}

