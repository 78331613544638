<h2 mat-dialog-title>
    Modify table column:
    <div class="mini-header-menu">
        <button mat-flat-button class="btn" (click)="changeColumn('prev')" [disabled]="isPrevBtn"><mat-icon>keyboard_arrow_left</mat-icon> Prev</button>
        <button mat-flat-button class="btn" (click)="changeColumn('next')" [disabled]="isNextBtn"><mat-icon iconPositionEnd>keyboard_arrow_right</mat-icon> Next</button>
    </div>
</h2>
<div mat-dialog-content>
    <form *ngIf="editColumnForm" [formGroup]="editColumnForm">
        <div class="row">
            <div class="col col-6">
                <h3>Update the details of selected column:</h3>
                <div class="form-input">
                    <mat-form-field>
                        <mat-label >Title:</mat-label>
                        <input matInput #strTitle maxlength="20" formControlName="strTitle">
                        <mat-hint align="end">{{strTitle.value.length}}/20</mat-hint>
                        <mat-error *ngIf="formError('strTitle', 'required')">Title is required</mat-error>
                        <mat-error *ngIf="formError('strTitle', 'maxlength')">Limit exceed</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-input">
                    <mat-form-field>
                        <mat-label>Type:</mat-label>
                        <mat-select #strType formControlName="strType">
                            <mat-option *ngFor="let item of valueType" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formError('strType', 'required')">Type is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-input">
                    <mat-form-field *ngIf="strType.value=='VALU'">
                        <mat-label>Value:</mat-label>
                        <mat-select #strDefinitionOpt formControlName="strDefinitionOpt">
                            <mat-option *ngFor="let item of fieldType" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formError('strDefinitionOpt', 'required')">Value is required</mat-error>
                    </mat-form-field>
                    <div *ngIf="strType.value=='FORM'">
                        <mat-form-field >
                            <mat-label>Definition</mat-label>
                            <textarea matInput #strDefinition formControlName="strDefinition"></textarea>
                            <mat-error *ngIf="formError('strDefinition', 'required')">Value is required</mat-error>
                        </mat-form-field>
                        <select #selectedOpt (change)='onOptChange(selectedOpt.value)'>
                            <option *ngFor="let item of fieldType" [value]="item.value">{{ item.label }}</option>
                        </select>
                        <button mat-flat-button class="small-btn" (click)="insertOpr()" color="primary">Insert</button>
                        <button mat-flat-button class="small-btn" (click)="insertOpr('+')" color="primary">+</button>
                        <button mat-flat-button class="small-btn" (click)="insertOpr('-')" color="primary">-</button>
                        <button mat-flat-button class="small-btn" (click)="insertOpr('*')" color="primary">*</button>
                        <button mat-flat-button class="small-btn" (click)="insertOpr('/')" color="primary">/</button>
                        <button mat-flat-button class="small-btn" (click)="insertOpr('(')" color="primary">(</button>
                        <button mat-flat-button class="small-btn" (click)="insertOpr(')')" color="primary">)</button>
                    </div>
                </div>
                <div class="form-input">
                    <mat-checkbox formControlName="bGroup">Grouped</mat-checkbox>
                    <mat-checkbox formControlName="bIsLink" (click)="toggleLinkParam()">Hyperlink</mat-checkbox>
                </div>
                <div class="form-input" *ngIf="showParamTotal">
                    <mat-form-field>
                        <mat-label >Hyperlink URL:</mat-label>
                        <input matInput #strLink formControlName="strLink">
                    </mat-form-field>
                </div>
                <div class="form-input" *ngIf="showParamTotal">
                    <mat-form-field>
                        <mat-label >Hyperlink params:</mat-label>
                        <input matInput #strLinkParams formControlName="strLinkParams">
                    </mat-form-field>
                </div>
            </div>
            <div class="col col-6">
                <h3>Column formatting:</h3>
                <div class="form-input form-container">
                    <mat-form-field>
                        <mat-label >Width:</mat-label>
                        <input matInput #iWidth type="number" min="10" max="500" formControlName="iWidth">
                        <mat-hint align="end">10px - 500px</mat-hint>
                        <span matTextSuffix>PX</span>
                        <mat-error *ngIf="formError('iWidth', 'required')">Width is required</mat-error>
                        <mat-error *ngIf="formError('iWidth', 'min')">Min 10 px</mat-error>
                        <mat-error *ngIf="formError('iWidth', 'max')">Max 500 px</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Align type:</mat-label>
                        <mat-select #iAlignType formControlName="iAlignType">
                            <mat-option *ngFor="let item of alignType" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formError('iAlignType', 'required')">Align type is required</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-input form-container">
                    <mat-form-field>
                        <mat-label>Format type:</mat-label>
                        <mat-select #strFormatType formControlName="strFormatType">
                            <mat-option *ngFor="let item of formatType" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="formError('strFormatType', 'required')">Format type is required</mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="strFormatType.value=='STRG'">
                        <mat-label>Max chars:</mat-label>
                        <input type="number" matInput formControlName="iFormatValue">
                    </mat-form-field>

                    <mat-form-field *ngIf="strFormatType.value=='FLOT'">
                        <mat-label>Max chars:</mat-label>
                        <mat-select formControlName="iFormatValue">
                            <mat-option *ngFor="let item of [].constructor(10);let i= index" [value]="i+1">{{ i+1 }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="strFormatType.value=='TIME'">
                        <mat-label>Time type:</mat-label>
                        <mat-select formControlName="iFormatValue">
                            <mat-option *ngFor="let item of timeType" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="strFormatType.value=='DATE'">
                        <mat-label>Date type:</mat-label>
                        <mat-select formControlName="iFormatValue">
                            <mat-option *ngFor="let item of dateType" [value]="item.value">{{ item.label }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button [disabled]="!editColumnForm.valid" color="primary" (click)="onSubmit()">Save</button>
</div>
