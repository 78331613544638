import { Injectable } from '@angular/core';
import { IContextService, IContextMessage, LayoutOptionType } from 'carina-workflow-assist'; 
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AssistContextService implements IContextService {

  private _action: Subject<IContextMessage> = new Subject();
  public readonly onAction: Observable<IContextMessage> = this._action.asObservable();

  public hasSession = true;
  public hasCarina = true;

  constructor(
    private appService: AppService,
    private authService: AuthService
  ) { };

  getBaseUrl(): string {

    return this.authService.getOrgUrl();

  }
  
  getServerSuffix(): string {

    return '/api/assist';

  } 

  setPageLayout (strLayout : string) : void {

    this.appService.setPageLayout(strLayout);

  };
  setPageTitle (strTitle : string) : void {
    this.appService.setPageTitle(strTitle);
  };


  setAction (action: string, data? : any) {

    let message : IContextMessage = {action: action, data: {}};

    if (data) {
      message.data = data;
    }

    this._action.next(message);

  }

  navigate(location: string): void {
    this.authService.navigate(location);
  }

  getDefaultLayout() : LayoutOptionType {

    return {
      stepView: {enable: true, flex: 5, title:"Manuscript Submission Checker"},
      dataView : {enable: true, flex: 5, title:"Manuscript Data"},
      actionView :  {enable: true, flex: 4, title:"Manuscript Actions",
        layout: [{id:"carina-actions", direction:"column", children:[]}]}
    }

  }

  pageReset(): void {

  }

  loadScript(scriptName: string) {

  }

  addUILanguage(uiLang) {

    //this.appService.addUILanguage(uiLang);

  }


}