// APP name
export const APP_TITLE: string = 'Carina';

// Layout category list
export const DEFAULT_LAYOUT: string = 'default';
export const WIDE_LAYOUT: string = 'wide';
export const DEFAULT_APP_WIDTH: number = 960;


// Layout respect class
export const DEFAULT_LAYOUT_CLASS: string = 'Page';
export const WIDE_LAYOUT_CLASS: string = 'WidePage';


// Page tile
export const PAGE_TITLE_HOMEPAGE: string = 'Homepage';
export const PAGE_TITLE_CONFIGURE: string = 'Configure Carina';
export const PAGE_TITLE_WORKFLOW: string = 'Workflow Assist';
