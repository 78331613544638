export enum PageType {
    REPORT = 'report',
    STAT = 'stat',
    USER = 'user',
    JOURNAL = 'journal',
    EDIT = 'edit',
    GROUP = 'group',
    ADD = 'add',
    COPY = 'copy',
    LIBRARY = 'library',
    DOCGROUP = 'document_group',
    DOCUMENT = 'document',
    TIMEENTRY = 'timeEntry'
}