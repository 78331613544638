import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VALUE_TYPE, ALIGN_TYPE, FORMAT_TYPE, TIME_TYPE, DATE_TYPE, FIELD_TYPE } from 'src/app/shared/constants';
import { IReportColumn, ISelectOption, IColumnConfig } from 'src/app/shared/interfaces';


@Component({
  selector: 'carina-dialog-modify-column',
  templateUrl: 'dialog-modify-column.component.html',
  styleUrls: ['dialog-modify-column.component.scss']
})
export class ModifyColumnDialogComponent implements OnInit {

  columnConfig: IReportColumn;
  columnIndex: number;
  showColumns: string[];
  columns: any;
  public editColumnForm: FormGroup;
  public valueType: ISelectOption[] = VALUE_TYPE;
  public alignType: ISelectOption[] = ALIGN_TYPE;
  public formatType: ISelectOption[] = FORMAT_TYPE;
  public timeType: ISelectOption[] = TIME_TYPE;
  public dateType: ISelectOption[] = DATE_TYPE;
  public fieldType: ISelectOption[] = FIELD_TYPE;
  showParamTotal: boolean;
  selectedOption: string = this.fieldType[0].value;
  isPrevBtn: boolean;
  isNextBtn: boolean;

  constructor(
    public dialogRef: MatDialogRef<ModifyColumnDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog
  ) { 
    dialogRef.disableClose = true;
    this.columnIndex = this.data?.columnIndex;
    this.showColumns = this.data?.showColumns;
    this.columns = this.data?.columns;

  } 

  ngOnInit(): void {
    this.initFormParam();  
  } 

  initFormParam(){
    if(this.showColumns[this.columnIndex]){
      let tempIndex = this.showColumns[this.columnIndex];
      this.columnConfig = this.columns.filter((elem) => elem.name === tempIndex);
      this.columnConfig = this.columnConfig[0] || [];
      this.buildForm();
    }
    this.togglePrevNext();  
  }

  togglePrevNext(){
    this.isPrevBtn = true;
    this.isNextBtn = true;
    if(this.columnIndex > 0){
      this.isPrevBtn = false;
    }
    if(this.showColumns.length > (this.columnIndex+1)){
      this.isNextBtn = false;
    }
  }

  buildForm(): void{
    this.editColumnForm = new FormGroup({
      strTitle: new FormControl(this.columnConfig?.strTitle, [Validators.required, Validators.maxLength(30)]),
      strType: new FormControl(this.columnConfig?.strType, [Validators.required]),
      strDefinitionOpt: new FormControl(this.columnConfig?.strDefinition, [Validators.required]),
      strDefinition: new FormControl(this.columnConfig?.strDefinition, [Validators.required]),
      bGroup: new FormControl(this.columnConfig?.bGroup, []),
      bIsLink: new FormControl(this.columnConfig?.bIsLink, []),
      strLink: new FormControl(this.columnConfig?.strLink, []),
      strLinkParams: new FormControl(this.columnConfig?.strLinkParams, []),
      iWidth: new FormControl(this.columnConfig?.iWidth, [Validators.required, Validators.min(10), Validators.max(500)]),
      strFormatType: new FormControl(this.columnConfig?.strFormatType, [Validators.required]),
      iAlignType: new FormControl(this.columnConfig?.iAlignType, [Validators.required]),
      iFormatValue: new FormControl(this.columnConfig?.iFormatValue, [Validators.required])
    });
    this.toggleLinkParam()
  }

  public formError = (controlName: string, errorName: string) =>{
    return this.editColumnForm.controls[controlName].hasError(errorName);
  }

  changeColumn(type: string): void {
    let colIndex: number = 1;
    if(type == 'prev'){
      colIndex = -1;
    }
    this.columnIndex = this.columnIndex+colIndex;
    this.initFormParam(); 
  }

  toggleLinkParam(): void {
    this.showParamTotal = this.editColumnForm.controls['bIsLink'].value;
  }

  onOptChange(value: string): void{
    this.selectedOption = value;
  }

  insertOpr(value: string): void {
    if(!value){
      value = this.selectedOption;
    }
    let param = this.editColumnForm.controls['strDefinition'].value;
    this.editColumnForm.controls['strDefinition'].setValue(param += value);
  }

  onSubmit(): void {
    if(this.editColumnForm.valid){
      let formParam = this.editColumnForm.value;
      if(formParam?.strType && formParam.strType == 'VALU'){
        formParam.strDefinition = formParam.strDefinitionOpt;
      }
      this.dialogRef.close({ data: formParam, saved: true });
    }
    return;
  }
}