import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService, AppService } from '../services';
import { PageRoutes, PermissionType } from 'src/app/shared/enums';
import { DEFAULT_LAYOUT, WIDE_LAYOUT } from 'src/app/shared/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor (
    private authService: AuthService,
    private appService: AppService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    let result : boolean = false;  
    let strLayout : string = DEFAULT_LAYOUT;
    
    if (this.authService.hasPermission(PermissionType.SGON)) {

      switch (next.url[0].path) {
        case PageRoutes.HOME:
          result = true;
        break;
        case PageRoutes.CONFIGURE:
          result = this.authService.hasPermission(PermissionType.MTAL);
        break;  
        // case 'stats':
        //   result = this.authService.hasPermission(PermissionType.JRST);
        // break;  
        case PageRoutes.ASSIST:
          result = true  //this.authService.hasPermission(PermissionType.MTAL);
          strLayout = WIDE_LAYOUT;
        break;  
        case PageRoutes.STATS:
        case PageRoutes.USERS:
        case PageRoutes.JOURNALS:
        case PageRoutes.REPORT:
          result = true  //this.authService.hasPermission(PermissionType.MTAL);
        break;              
      }
    }

    if (result) {
      this.appService.setPageLayout(strLayout);
    }

    return result;
  }
}
