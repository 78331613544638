import { EnumHeaderControlType } from "../enums/enum-header-control-type";
import { HeaderControl } from "./header-control";
import { IAppMenuButton } from '../interfaces';

export class HeaderButton extends HeaderControl {
  public id :string;
  public name : string;
  public title : string;
  public disabled : boolean;
  public action : string;
  public method : string;

  constructor(menu: IAppMenuButton) {

    super(EnumHeaderControlType.HeaderButton, menu.id, menu.name);

    this.id = menu.id;
    this.name = menu.name;
    this.title = menu.title;
    this.disabled = menu.disabled;
    this.action = menu.action;
    this.method = menu.method;
  }
}
