import { IReportColumn } from "../interfaces/ireport-column";

export class ReportColumn {

  name : string;
  style : object;
  index : number;
  strTitle: string;
  bGroup: boolean;
  strFormatType: string;
  iFormatValue: number;
  strClass: string;
  iWidth: number;
  iAlignType: number;
  bIsLink: boolean;
  strLink: string;
  iSortIndex: number;
  strType: string;
  strDefinition: string;
  strLinkParams: string;

  constructor(config : IReportColumn, index : number) {
    this.strTitle = config.strTitle;
    this.bGroup = config.bGroup;
    this.strFormatType = config.strFormatType;
    this.iFormatValue = config.iFormatValue;
    this.strClass = config.strClass;
    this.iWidth = config.iWidth;
    this.iAlignType = config.iAlignType;
    this.bIsLink = config.bIsLink;
    this.strLink = config.strLink;
    this.iSortIndex = config.iSortIndex;
    this.strType = config.strType;
    this.strDefinition = config.strDefinition;
    this.strLinkParams = config.strLinkParams;
    this.style = config.style || {};


    if (index > 0) {
      this.name = "Col"+ index;
    }

    this.index = index;

  }

}