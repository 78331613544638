import { IAppMenuButton } from '../interfaces';

export const COLUMN_MENU: IAppMenuButton[] = [
    {
        id: 'colBtnCut', 
        name: 'cut', 
        title: 'Cut', 
        disabled: false, 
        action: "initColumnCut",
        method: 'func'
    },  {
        id: 'colBtnCopy', 
        name: 'copy', 
        title: 'Copy', 
        disabled: false, 
        action: "initColumnCopy",
        method: 'func'
    }, {
        id: 'colBtnPaste', 
        name: 'paste', 
        title: 'Paste', 
        disabled: true, 
        action: "initColumnPaste",
        method: 'func'
    }, {
        id: 'colBtnForward', 
        name: 'forward', 
        title: 'Forward', 
        disabled: false, 
        action: "initColumnForward",
        method: 'func'
    }, {
        id: 'colBtnBackward', 
        name: 'backward', 
        title: 'Backward', 
        disabled: true, 
        action: "initColumnBackward",
        method: 'func'
    }, {
        id: 'colBtnAdd', 
        name: 'add', 
        title: 'Add', 
        disabled: false, 
        action: "initColumnAdd",
        method: 'func'
    }, {
        id: 'colBtnEdit', 
        name: 'edit', 
        title: 'Edit', 
        disabled: false, 
        action: "initColumnEdit",
        method: 'func'
    }
];