import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, UrlSegmentGroup, DefaultUrlSerializer, PRIMARY_OUTLET, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../services/auth.service';
import { AppService } from 'src/app/shared/services/app.service'

@Injectable({
  providedIn: 'root'
})
export class OrgGuard  {
  constructor (
    private router: Router,
    //private state: RouterStateSnapshot,
    private cookies: CookieService,
    private authService: AuthService,
    private appService: AppService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
     
    let strOrgUrl = next.paramMap.get('orgUrl');  

    //this.authService.checkSession().subscribe();  

    if (!this.authService.hasUser() && !this.authService.hasOrg()) {

      let strSessionId : string = this.cookies.get("PHPSESSID");

      if (strSessionId && this.authService.validateOrg(strOrgUrl)) {

        let auth$ = this.authService.restartSession(strSessionId, strOrgUrl);

        let request$ = auth$.pipe (
          map(result => {
            if (result) {
              return this.doValidation(strOrgUrl);
            } else {
              let tree: UrlTree = this.router.parseUrl(state.url);
              let sg: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];              

              if (next !== undefined && state !== undefined && (next.queryParamMap.has("page") || next.queryParamMap.has("demo"))) {

                if (sg.segments[0].path === strOrgUrl && 
                    sg.segments[1].path === "signon") {

                  return this.doValidation(strOrgUrl);
                } else {
                  return this.router.parseUrl(strOrgUrl + '/signon');
                }

              } else {

                if (sg.segments.length > 1 && 
                    sg.segments[0].path === strOrgUrl &&
                    next.data.hasOwnProperty("ngRoutes") &&
                    Array.isArray(next.data.ngRoutes) &&
                    next.data.ngRoutes.indexOf(sg.segments[1].path) >= 0) {
                      
                  return this.router.parseUrl(strOrgUrl + '/signon?page=' + encodeURIComponent(state.url));    

                } else {
                  return this.router.parseUrl(strOrgUrl + '/signon');
                }
              }
            }
          })
        )

        return request$; 

      } else {
        return this.doValidation(strOrgUrl);
      }
    } else {
      return this.doValidation(strOrgUrl);
    } 
  }
  

  doValidation (strOrgUrl : string) {
    if (this.authService.matchOrg(strOrgUrl)) {
      return true;
    } else {
      if (this.authService.validateOrg(strOrgUrl)) {
        return this.router.parseUrl(strOrgUrl + '/signon');
      } else {
        window.location.href = 'https://taskadept.com';
      }
    }  

  }


}
