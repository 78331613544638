import { Component, Input, Output, OnChanges, EventEmitter } from '@angular/core';
import { DateTimeService } from 'src/app/shared/services';
import { DateController } from 'src/app/shared/enums';

@Component({
  selector: 'carina-date-control',
  templateUrl: './date-control.component.html',
  styleUrls: ['./date-control.component.scss']
})
export class DateControlComponent implements OnChanges{

    @Input() disableBtn: boolean;
    @Input() selectedDate: Date;
    @Input() maxDate: Date;
    @Input() isMonthPicker: boolean;
    @Output() changDate = new EventEmitter<Date>();
    dateController = DateController;

    constructor(private dateService: DateTimeService) {  }

    ngOnChanges(): void {
        this.toggleBtn();
    }

    updateDate(type: string): void{
        let existDate = this.selectedDate;
        switch(type){
          case this.dateController.PREV:
            this.selectedDate = this.isMonthPicker ? this.dateService.minusMonth(existDate, 1): this.dateService.minusDays(existDate, 1);
          break;
          case this.dateController.NEXT:
            if(!this.disableBtn){
              this.selectedDate = this.isMonthPicker ? this.dateService.addMonth(existDate, 1) : this.dateService.addDays(existDate, 1);
            }
          break;
          case this.dateController.TODAY:
            if(!this.disableBtn){
              this.selectedDate = this.isMonthPicker ? this.dateService.currentMonth() : this.dateService.currentDateTime();
            }
          break;
        }
        this.toggleBtn();
        this.changDate.next(this.selectedDate);
    }

    toggleBtn(): void{
        if(this.selectedDate && this.maxDate && this.dateService.toUnixTime(this.maxDate) > this.dateService.toUnixTime(this.selectedDate)){
          this.disableBtn = false;
        }else{
          this.disableBtn = true;
        }
    }
}
 