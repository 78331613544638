import { MONTH_YEAR_LABEL, MONTH_YEAR_A11Y_LABEL, DATE_A11Y_LABEL, DEFAULT_DATE_LABEL, MAT_DATE_LABEL } from 'src/app/shared/constants';
export class CustomDateFormat {
  pattern: string;

  get display() {
    this.pattern = this.pattern?.replace(DEFAULT_DATE_LABEL, MAT_DATE_LABEL);
    return {
      dateInput: this.pattern,
      monthYearLabel: MONTH_YEAR_LABEL,
      dateA11yLabel: DATE_A11Y_LABEL,
      monthYearA11yLabel: MONTH_YEAR_A11Y_LABEL,
    };
  }

  get parse() {
    return { dateInput: this.pattern?.replace(DEFAULT_DATE_LABEL, MAT_DATE_LABEL) };
  }
}
