// App menu
import { IAppMenuButton, IAppMenuSelect } from '../interfaces';

export const APP_MENU_HOME: IAppMenuButton = {
    id: 'hdrbtnHome', 
    name: 'home', 
    title: 'Home', 
    disabled: false, 
    action: '/home',
    method: 'link'
};

export const APP_MENU_CONFIGURE: IAppMenuButton = {
    id: 'hdrbtnConfigure', 
    name: 'configure', 
    title: 'Configure Carina', 
    disabled: false, 
    action: '/configure',
    method: 'link'
};

export const APP_MENU_ASSIST: IAppMenuButton = {
    id: 'hdrbtnAssist', 
    name: 'assist', 
    title: 'Workflow Assist', 
    disabled: false, 
    action: '/assist',
    method: 'link'
};

export const APP_MENU_EXPORT_CSV: IAppMenuButton = {
    id: 'hdrbtnExpCSV', 
    name: 'exportcsv', 
    title: 'Export CSV', 
    disabled: false, 
    action: "initExportCSV",
    method: 'func'
};

export const APP_MENU_EXPORT_SPR: IAppMenuButton = {
    id: 'hdrbtnExpSpr', 
    name: 'exportspr', 
    title: 'Export Spr', 
    disabled: false, 
    action: "initExportSPR",
    method: 'func'
};

export const APP_MENU_RESET: IAppMenuButton = {
    id: 'hdrbtnReset', 
    name: 'reset', 
    title: 'Reload', 
    disabled: false, 
    action: "initReset",
    method: 'func'
};

export const APP_MENU_SUBMIT: IAppMenuButton = {
    id: 'hdrbtnSubmit', 
    name: 'submit', 
    title: 'Submit', 
    disabled: false, 
    action: "initSubmitReport",
    method: 'func'
};

export const APP_MENU_CANCEL: IAppMenuButton = {
    id: 'hdrbtnCancel', 
    name: 'cancel', 
    title: 'Cancel', 
    disabled: false, 
    action: "initCancel",
    method: 'func'
};

export const APP_MENU_EDIT_REPORT: IAppMenuButton = {
    id: 'hdrbtnEditReport', 
    name: 'editReport', 
    title: 'Edit report', 
    disabled: false, 
    action: "initEditReport",
    method: 'func'
};

export const APP_MENU_COPY_REPORT: IAppMenuButton = {
    id: 'hdrbtnCopyReport', 
    name: 'copyReport', 
    title: 'Copy report', 
    disabled: false, 
    action: "initCopyNew",
    method: 'func'
};

export const APP_MENU_ADD_NEW: IAppMenuButton = {
    id: 'hdrbtnAddNew', 
    name: 'addNew', 
    title: 'Add new', 
    disabled: false, 
    action: "initAddNew",
    method: 'func'
};

export const APP_MENU_EDIT: IAppMenuSelect = {
    id: 'hdrbtnEdit', 
    name: 'edit', 
    title: 'Configure', 
    disabled: false, 
    opts: [APP_MENU_EDIT_REPORT, APP_MENU_COPY_REPORT]
};

export const APP_MENU_ADD: IAppMenuButton = {
    id: 'hdrbtnAdd', 
    name: 'add', 
    title: 'Add', 
    disabled: false, 
    action: "initReset",
    method: 'func'
};

export const APP_MENU_SAVE: IAppMenuButton = {
    id: 'hdrbtnSave', 
    name: 'save', 
    title: 'Save', 
    disabled: false, 
    action: "initSave",
    method: 'func'
};
