<mat-toolbar>
  <mat-toolbar-row *ngIf="!bDemo" class="headerBar">
    <img height="40px" [src]="strBannerSrc" alt="Customer Banner">
    <span class="spacer">
      <div class="elements"> 
        <div *ngIf="user" class="fabs">
          <!-- <button *ngIf="hasMaint()" color="basic" class="carina-mini-fab" mat-mini-fab (click)="onSettings()">
            <mat-icon>settings</mat-icon>
          </button>
          <button color="basic" class="carina-mini-fab" mat-mini-fab (click)="onHome()">
            <mat-icon>home</mat-icon>
          </button> -->
          <button color="primary" class="carina-mini-fab" mat-mini-fab (click)="onUser()" title="Sign-off">
            <mat-icon>logout</mat-icon>
          </button>
        </div>
        <span>{{strFullName}}</span>
      </div>
    </span>
    <img height="50px" src="assets/images/Carina-Purple-RGB.svg" class="hidden-mb" alt="Carina">
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="bDemo && user" class="headerBar">
    <img height="50px" src="assets/images/Carina-Purple-RGB.svg" class="hidden-mb" alt="Carina">
    <span class="spacer">
      <div class="elements" style="flex-direction:row;justify-content:flex-end;">
        <span>{{strFullName}}</span>
        <div *ngIf="user" class="fabs" style="justify-content:flex-end;width:36px;margin-right:6px">
          <button color="primary" class="carina-mini-fab" mat-mini-fab (click)="onUser()" title="Sign-off">
            <mat-icon>logout</mat-icon>
          </button>
        </div>
      </div>
    </span>
  </mat-toolbar-row>
  <mat-toolbar-row *ngIf="strPageTitle" class="pageBar">
    <h1>
      {{strPageTitle}}
      <mat-icon 
        (click)="menuService.initEditForm()"
        [class.hide]="!enableEdit"
        matTooltip="Edit Title" 
        [matTooltipShowDelay]="2000">
        mode_edit
      </mat-icon>
    </h1>
    <div style="flex:1 1 auto; display:flex; justify-content: flex-end;">
      <carina-control-host class="headerHost" [controls]="arrHeaderControls"></carina-control-host>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

