import { ISelectOption } from '../interfaces';

export const VALUE_TYPE: ISelectOption[] = [
    { value: 'VALU', label: 'Value' },
    { value: 'FORM', label: 'Formula' }
];

export const ALIGN_TYPE: ISelectOption[] = [
    { value: 1, label: 'Left' },
    { value: 2, label: 'Center' },
    { value: 3, label: 'Right' }
];

export const FORMAT_TYPE: ISelectOption[] = [
    { value: "INTG", label: "Integer" },
    { value: "TIME", label: "Time" },
    { value: "FLOT", label: "Decimal" },
    { value: "STRG", label: "Text" },
    { value: "DATE", label: "Date" }
];

export const DATE_TYPE: ISelectOption[] = [
    { value: 0, label: 'dd/mm/yyyy' },
    { value: 1, label: 'dd/mm/yy' },
    { value: 2, label: 'dd/mm' },
    { value: 3, label: 'Monday' },
    { value: 4, label: 'Mon' },
    { value: 5, label: 'Monday 17' },
    { value: 6, label: 'Mon 17' },
    { value: 7, label: '17 February' },
    { value: 8, label: '17 Feb' },
    { value: 9, label: 'February' },
    { value: 10, label: 'Feb' }
];


export const TIME_TYPE: ISelectOption[] = [
    { value: 0, label: 'HH:mm:ss' },
    { value: 1, label: 'H:mm:ss' },
    { value: 2, label: 'mm:ss' },
    { value: 3, label: 'duration (hrs)' },
    { value: 4, label: 'duration (min)' },
    { value: 5, label: 'duration (sec)' },
    { value: 6, label: 'dd/mm/yyyy  HH:mm:ss' },
    { value: 7, label: 'yyyy/mm/dd HH:mm:ss' },
];

export const FIELD_TYPE: ISelectOption[] = [
    { value: 'firstName', label: 'First Name' },
    { value: 'fullName', label: 'Full Name' },
    { value: 'journalFullName', label: 'Journal Full Name' },
    { value: 'journalShortName', label: 'Journal Short Name' },
    { value: 'groupName', label: 'Journal Group Name' },
    { value: 'taskCode', label: 'Task Code' },
    { value: 'taskName', label: 'Task Name' },
    { value: 'startDate', label: 'Start Date' },
    { value: 'stopDate', label: 'Stop Date' },
    { value: 'submissionDate', label: 'Submission Date' },
    { value: 'manuscriptNum', label: 'Manuscript Number' },
    { value: 'title', label: 'Title' },
    { value: 'updated', label: 'Updated' },
];