import { Injectable } from '@angular/core';
import { IReportMaint } from '../../report/interfaces';

@Injectable({
  providedIn: 'root'
})

export class MaintDataService {

  data : IReportMaint;
  rowData : any[];

  constructor() {}

  setdata(cellIndex : number, options : any, rowData : any) {
    this.data = {cellIndex : cellIndex, options: options, rowData : rowData};
  }

  getdata() : IReportMaint {
    return this.data;
  }
  
}