import { IContractedJournal } from 'src/app/modules/maintenance/interfaces/';

export const CONTRACTED_JOURNAL: IContractedJournal[] = [
    { 
        iLocationId: 14946,
        startDate: 1676851200000,
        endDate: 1676851200000,
        numHours: 8,
        strShortName: 'Bioengineered Test',
        iDuration: 480,
        iContractId: 0,
        bDeleted: false
    }, { 
        iLocationId: 14959,
        startDate: 1676851200000,
        endDate: 1676851200000,
        numHours: 8,
        strShortName: 'BB',
        iDuration: 480,
        iContractId: 0,
        bDeleted: false
    }, { 
        iLocationId: 15271,
        startDate: 1676851200000,
        endDate: 1676851200000,
        numHours: 8,
        strShortName: '2DM',
        iDuration: 480,
        iContractId: 0,
        bDeleted: false
    }
];
