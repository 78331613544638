import { ListEntry } from 'src/app/shared/interfaces/list-entry';
import { EntryList } from 'src/app/shared/classes/entry-list'
import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'carina-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent implements OnInit {

  @Input() arrEntries: ListEntry[];
  @Input() strName: string;
  @Input() bShowCount: boolean;
  @Input() strColour: string;
  @Output() selected = new EventEmitter<string>();
  entryList: EntryList;
  strTitle: string;
  iCount: number;

  constructor() { }

  ngOnInit(): void {
    this.entryList = new EntryList(this.arrEntries);
    this.strTitle = this.strName;
    this.iCount = this.entryList.count()
    
    if (this.bShowCount) {
      this.strTitle += ' (' + this.iCount + ')';
    }    

  }

  select(value: string) {
    this.selected.emit(value);
  }
}
