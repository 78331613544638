import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { HeaderButton } from '../classes/header-button';
import { HeaderSelect } from '../classes/header-select';
import { HeaderControl } from '../classes/header-control';
import { DEFAULT_LAYOUT} from 'src/app/shared/constants'

@Injectable({
  providedIn: 'root'
})

export class AppService {

  private _pageLayout: BehaviorSubject<string> = new BehaviorSubject(null);
  public readonly pageLayout: Observable<string> = this._pageLayout.asObservable();

  private _pageTitle: BehaviorSubject<string> = new BehaviorSubject(null);
  public readonly pageTitle: Observable<string> = this._pageTitle.asObservable();

  private _inProgress: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public readonly inProgress: Observable<boolean> = this._inProgress.asObservable();

  private _controls: BehaviorSubject<[]> = new BehaviorSubject(null);
  public readonly controls: Observable <[]> = this._controls.asObservable();

  private _headerControls: BehaviorSubject<HeaderControl[]> = new BehaviorSubject(null);
  public readonly headerControls: Observable<HeaderControl[]> = this._headerControls.asObservable();

  private _headerButtonPress: Subject<HeaderButton> = new Subject();
  public readonly onHeaderButtonsPress: Observable<HeaderButton> = this._headerButtonPress.asObservable();

  private _headerSelection: Subject<HeaderSelect> = new Subject();
  public readonly onHeaderSelection: Observable<HeaderSelect> = this._headerSelection.asObservable();


  constructor(
    private router: Router 
  ) {

    router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        // Delete if not used
      }
    });   

    this._pageTitle.next("");
    this._pageLayout.next(DEFAULT_LAYOUT);    
    this._inProgress.next(false);
    
  }

  setPageTitle (strTitle : string)  {
  this._pageTitle.next(strTitle);
  } 

  setPageLayout (strLayout : string)  {
    this._pageLayout.next(strLayout);
  } 

  setInProgress (flag : boolean)  {
    this._inProgress.next(flag);
  } 

  setControls(controls: []) {
    this._controls.next(controls);
  } 

  setHeaderButtons(controls: HeaderControl[]) {
    this._headerControls.next(controls);
  } 
  
  headerButtonPress(button: HeaderButton) {
    this._headerButtonPress.next(button);
  }

  headerSelected(select: HeaderSelect) {
    this._headerSelection.next(select);
  }


}
