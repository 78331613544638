export const JOURNAL_FORM_PARAM: object[] = [
    { 
        title: "Statistics", 
        id: 'arrLinkedStats'
    }, {
        title: "Users", 
        id: 'arrOperators'
    }
];

export const JOURNAL_CONFIG_PARAM = {
    arrLinkedStats: [],
    arrOperators: []
};
