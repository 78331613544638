export enum PermissionType {
    SGON = 'SGON',
    MTAL = 'MTAL',
    JRST = 'JRST',
    WFAS = 'WFAS',
    REPT = 'REPT',
    LIBR = 'LIBR',
    TMSH = 'TMSH',
    STSH = 'STSH',
    EMST = 'EMST',
    EMCJ = 'EMCJ',
    EMFT = 'EMFT',
    EDTM = 'EDTM',
    MTPW = 'MTPW',
    MAEN = 'MAEN',
}
