// Default angular format
export const DEFAULT_DATE_FORMAT: string = 'YYYY-MM-dd 00:00:00';
export const DEFAULT_MONTH_FORMAT: string = 'MM/yyyy';
export const DEFAULT_DATE_LABEL: string = 'dd';
export const DEFAULT_MONTH_LABEL: string = 'MM';
export const DEFAULT_YEAR_LABEL: string = 'yyyy';

// MATERIAL LABELS
export const MAT_MONTH_FORMAT: string = 'MM/YYYY';
export const MONTH_YEAR_LABEL: string = 'MMM YYYY';
export const MONTH_YEAR_A11Y_LABEL: string = 'MMMM YYYY';
export const DATE_A11Y_LABEL: string = 'LL';
export const MAT_DATE_LABEL: string = 'DD';

// Locale variable for angular date format
export const LOCALE_DATE_FORMAT: object = {
    'en': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
    'en-GB': 'dd/MM/yyyy',
    'en-IN': 'dd/MM/yyyy',
};