import { Component, Input, SimpleChanges, Type, ViewChild, ComponentRef, OnChanges} from '@angular/core';
import { HeaderControl } from '../../classes/header-control';
import { ControlsDirective } from '../../directives/controls-element.directive';
import { EnumHeaderControlType } from '../../enums/enum-header-control-type';
import { IControlComponent } from '../../interfaces/icontrol-component';
import { HeaderButtonComponent } from '../controls/header-button.component';
import { HeaderSelectComponent } from '../controls/header-select.component';

@Component({
  selector: 'carina-control-host',
  templateUrl: 'control-host-component.html',
})
export class ControlHostComponent implements OnChanges {

  @Input() controls: HeaderControl[];
  @ViewChild(ControlsDirective, { static: true }) layoutRef: ControlsDirective;

  constructor() {
  }
  
  ngOnChanges(changes: SimpleChanges) {

    this.createControls();

  }

  createControls() {

    this.clearControls();

    let componentRef : ComponentRef<IControlComponent> = null;
    let component: Type<IControlComponent> = null;
    
    const viewContainerRef = this.layoutRef.viewContainerRef;

    this.controls.forEach((control : HeaderControl) => {
      switch(control.type) {
        case EnumHeaderControlType.HeaderButton:
          component = HeaderButtonComponent;
        break;
        case EnumHeaderControlType.HeaderSelect:
          component = HeaderSelectComponent;
        break;
        
      }

      componentRef = viewContainerRef.createComponent<IControlComponent>(component);
              
      componentRef.instance.controlData = control;
    });
  }


  clearControls() {
    this.layoutRef.viewContainerRef.clear();
  }
}