import { Component, OnInit } from '@angular/core';
import { AppService, AppMenuService } from '../../services'
import { HeaderButton } from '../../classes/header-button';
import { IControlComponent } from '../../interfaces/icontrol-component';

@Component({
  selector: 'carina-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: []
})
export class HeaderButtonComponent implements IControlComponent {

  controlData: HeaderButton;

  constructor(
    private appService: AppService,
    private menuService: AppMenuService
  ) {
  }

  onClick(control): void { 
    if(control.method == 'func'){
      this.menuService[control.action]();
    }else{
      this.appService.headerButtonPress(this.controlData);
    }
  }
}
