import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService, AppService, AppMenuService } from '../../services/'
import { Organisation } from '../../classes/organisation'
import { User } from '../../classes/user';
import { HeaderButton } from '../../classes/header-button';
import { HeaderControl } from '../../classes/header-control';
import { PermissionType } from 'src/app/shared/enums';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'carina-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  org : Organisation;
  user : User;
  bDemo : boolean;
  strFullName : string;
  strBannerSrc : string;
  strPageTitle : string;
  arrHeaderControls: HeaderControl[];
  bInProgress : boolean;
  enableEdit: boolean;
  enableCopy: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private appService: AppService,
    private menuService: AppMenuService,
    private router: Router
  ) {

    this.bDemo = false;

    this.activatedRoute.queryParams.subscribe(params => {
      //let date = params['demo'];
      //console.log(date); // Print the parameter to the console. 
    });

    authService.currentUser.subscribe(user => {
      this.user = user;
      if (this.user) {
        this.strFullName = this.user.fullName;
      } else {
        this.strFullName = "";
      }
    });

    authService.currentOrg.subscribe(org => {
      this.org = org;
      if (this.org) {
        let strOrg = this.org.getUrl();
        this.strBannerSrc = 'assets/images/' + strOrg + '/' + strOrg + '_banner.jpg?v=' + environment.version;
      } else {
        this.strBannerSrc = "";
      }
    });

    appService.pageTitle.subscribe(title => {
      this.strPageTitle = title;
    });


    appService.inProgress.subscribe(flag => {
      this.bInProgress = flag;
    });

    appService.headerControls.subscribe(controls => {
      this.arrHeaderControls = controls;
    });

    appService.onHeaderButtonsPress.subscribe(button => {
      this.onHeaderButton(button);
    });

    menuService.editReport.subscribe(response => {
      if(response){
        this.toggleEdit(response);
      }
    });

    menuService.copyNewItem.subscribe(response => {
      if(response){
        this.toggleCopy(response);
      }
    });

    router.events.subscribe((val) => {
        this.toggleEdit('')
    });
    
    this.strFullName = "";
    this.strPageTitle = "";
    this.bInProgress = false;
    this.arrHeaderControls = [];

  }

  ngOnInit(): void {

  }

  hasUser() : boolean { 
    return this.user !== null;
  }

  hasMaint(): boolean {
    return this.user && this.user.hasPermission(PermissionType.MTAL);
  }

  onUser() { 
    this.authService.navigate('/signon');
  }

  onSettings () { 
    this.authService.navigate('/configure');
  }

  onHome (){ 
    this.authService.navigate('/home');
  }

  onHeaderButton(button: HeaderButton) {
    if (button.action) {
      this.authService.navigate(button.action);
    } else {
      //this.appService.headerButtonPress(button);
    }
  }

  toggleEdit(value: string){
    if(value === 'edit'){
      this.enableEdit = true;
    }else{
      this.enableEdit = false;
    }
  }

    toggleCopy(value: string){
    if(value === 'copy'){
      this.enableCopy = true;
    }else{
      this.enableCopy = false;
    }
  }
}
