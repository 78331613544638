import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fn',
  pure: true
})
export class FnPipe implements PipeTransform {

  transform(inputValue: any, fnReference: Function, ...fnArguments: any[]): any {

    fnArguments.unshift( inputValue );
    
    return (fnReference.apply(null, fnArguments) );;
  }

}
