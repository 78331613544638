
import { EnumHeaderControlType } from '../enums/enum-header-control-type';

export class HeaderControl {

  type : EnumHeaderControlType;
  id : string;
  name : string;

  constructor(type: EnumHeaderControlType, id : string, name: string) {
    this.type = type;
    this.id = id;
    this.name = name;
  }

}
