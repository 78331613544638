import { AfterViewInit, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CdkDragDrop, CdkDropList, CdkDropListGroup, moveItemInArray, CdkDrag, DragRef, CdkDragEnter, CdkDragMove } from '@angular/cdk/drag-drop';

import { IOptionValue } from '../../interfaces';

@Component({
  selector: 'carina-dialog-drag-and-drop',
  templateUrl: './dialog-drag-and-drop.component.html',
  styleUrls: ['./dialog-drag-and-drop.component.scss']
})
export class DragAndDropDialogComponent implements AfterViewInit {
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  private target: CdkDropList = null;
  private targetIndex: number;
  private source: CdkDropList = null;
  private sourceIndex: number = null;
  private otherSourceIndex: number;
  private dragRef: DragRef = null;
  private rawArrLinkedOpts: any = [];
  private rawArrUnlinkedOpts: any = [];
  dialogTitle: string = "";
  arrStatsValue: IOptionValue[];
  arrStats: any = [];
  arrLinkedOpts: any = [];
  arrUnlinkedOpts: any = [];
  searchText: string = '';
  boxWidth = '100%';
  boxHeight = 'auto';

  constructor(
      public dialogRef: MatDialogRef<DragAndDropDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
      this.dialogTitle = this.data.title;
      this.arrStatsValue = Object.assign([], data?.arrStatsValue);
      this.rawArrLinkedOpts = data?.arrLinkedOpts;
      this.rawArrUnlinkedOpts = data?.arrUnlinkedOpts;
      this.correntDisplayItems();
    }

  ngAfterViewInit() {
    const placeholderElement = this.placeholder.element.nativeElement;

    placeholderElement.style.display = 'none';
    placeholderElement.parentNode.removeChild(placeholderElement);
  }

  onDropListEntered({ item, container }: CdkDragEnter) {
    // console.log(item, container)
    if (container == this.placeholder ) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = container.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement
    );
    const dropIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      dropElement
    );

    if (!this.source) {  
      let is_linked_list = item.element.nativeElement.classList.contains('linked')
      let is_unlinked_list = item.element.nativeElement.classList.contains('unlinked')
      if ( !is_linked_list || !is_unlinked_list ) {
        this.sourceIndex = dragIndex;
        this.source = item.dropContainer;
      }

      placeholderElement.style.width = this.boxWidth + 'px';
      placeholderElement.style.height = this.boxHeight + 40 + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop
    );
  }

  moveToSelectedItem(event, arrValues, itemClass) {
    let classList = event.previousContainer.element.nativeElement.classList;
    let movingItem = null;
    if ( classList.contains(itemClass) && ! this.isOutsideSelectedList(event.dropPoint.y) ) {
      movingItem = this.dropIndex(arrValues, this.otherSourceIndex);
      if ( ! event.isPointerOverContainer ) {
        this.targetIndex = this.arrStatsValue.length;
      }
      this.updateIndex(this.arrStatsValue, this.targetIndex, movingItem);
      classList.remove(itemClass);
      classList.add('selected-item');
      return true;
    }
    return false
  }

  dropSelected(event: CdkDragDrop<any[]>) {
    // console.log(event)
    let movingItem = null;
    let classList = event.previousContainer.element.nativeElement.classList;
    // console.log(this.sourceIndex, classList, this.isOutsideSelectedList(event.dropPoint.y));
    let matchCondition = this.moveToSelectedItem(event, this.arrLinkedOpts, 'linked-item');
    if ( ! matchCondition ) {
      matchCondition = this.moveToSelectedItem(event, this.arrUnlinkedOpts, 'unlinked-item');
    }
    if ( ! matchCondition && classList.contains('selected-item') ) {
      if ( this.sourceIndex ===  null ) {
        this.sourceIndex = this.arrStatsValue.length - 1;
      }
      movingItem = this.dropIndex(this.arrStatsValue, this.sourceIndex);
      if ( this.isOutsideSelectedList(event.dropPoint.y) ) {
        this.correntDisplayItems();
      } else {
        this.updateIndex(this.arrStatsValue, this.targetIndex, movingItem);
      }
    }

    if (!this.target) {
      this.clearAllDragValues();
      return;
    }

    if ( this.source.element ) {
      const placeholderElement: HTMLElement =
        this.placeholder.element.nativeElement;
      const placeholderParentElement: HTMLElement =
        placeholderElement.parentElement;

      placeholderElement.style.display = 'none';

      placeholderParentElement.removeChild(placeholderElement);
      placeholderParentElement.appendChild(placeholderElement);
      placeholderParentElement.insertBefore(
        this.source.element.nativeElement,
        placeholderParentElement.children[this.sourceIndex]
      );

      if (this.placeholder._dropListRef.isDragging()) {
        this.placeholder._dropListRef.exit(this.dragRef);
      }
    }
    this.clearAllDragValues();
  }

  clearAllDragValues() {
    this.target = null;
    this.source = null;
    this.dragRef = null;
    this.sourceIndex = null;
    this.otherSourceIndex = null;
  }

  drop(event: CdkDragDrop<any[]>) {
    if ( event.previousContainer.id == 'selected-list-item' ) {
      let value = this.dropIndex(this.arrStatsValue, event.previousIndex);
      this.updateIndex(this.arrLinkedOpts, event.currentIndex, value);
    }
  }

  @ViewChild('selectedList') selectedList:ElementRef
  isOutsideSelectedList(drag) {
    let clientRect = this.selectedList.nativeElement.getBoundingClientRect();
    let layout = clientRect.height + clientRect.y;
    return layout < drag;
  }

  entredSelettedList(event) {
    let drag = event.pointerPosition.y
    return this.isOutsideSelectedList(drag);
  }
  
  onDraggingSelectedList(event) {
    if ( this.entredSelettedList(event) ) {
      this.enteredDelete();
    } else {
      this.enteredAdding();
    }
  }

  onDraggingNonSelectedList(event, index) {
    if (!this.source) {
      this.otherSourceIndex = index;
    }
    if ( this.entredSelettedList(event) ) {
      this.removeIcon();
    } else {
      this.enteredAdding();
    }
  }

  removeIcon() {
    let ele = this.getIconEle();
    if ( !ele ) {
      return;
    }
    ele.classList.remove('isAdding');
  }

  enteredAdding() {
    let ele = this.getIconEle();
    if ( !ele ) {
      return;
    }
    if ( ! ele.classList.contains('isMoving') ) {
      ele.classList.add('isAdding');
    }
    ele.classList.remove('isDeleting');
  }

  enteredDelete() {
    let ele = this.getIconEle();
    if ( !ele ) {
      return;
    }
    ele.classList.add('isDeleting');
  }

  getIconEle() {
    return document.querySelector('.cdk-drag-preview span');
  }

  dropIndex(arrOpts, index) {
    let value = arrOpts[index];
    arrOpts.splice(index, 1);
    return value;
  }

  updateIndex(arrOpts, index, value) {
    arrOpts.splice(index, 0, value);
  }

  save() {
    let data = {
      saved: true,
      arrStatsValue: this.arrStatsValue,
      arrStats: this.arrStats
    }
    this.dialogRef.close(data);
  }

  correntDisplayItems(){
    this.arrStats = [];
    this.arrStatsValue.forEach(item => {
      this.arrStats.push(item.val);
    });
    this.arrLinkedOpts = [];
    this.rawArrLinkedOpts.forEach(item => {
      if ( this.arrStats.includes(item.val) ) {
        return;
      }
      if ( this.searchText !== "" ) {
        if ( item.txt.toLowerCase().includes(this.searchText.toLowerCase()) ) {
          this.arrLinkedOpts.push(item);
        }
      } else {
        this.arrLinkedOpts.push(item);
      }
    });
    this.arrUnlinkedOpts = [];
    this.rawArrUnlinkedOpts.forEach(item => {
      if ( this.arrStats.includes(item.val) ) {
        return;
      }
      if ( this.searchText !== "" ) {
        if ( item.txt.toLowerCase().includes(this.searchText.toLowerCase()) ) {
          this.arrUnlinkedOpts.push(item);
        }
      } else {
        this.arrUnlinkedOpts.push(item);
      }
    });
  }
}
