<div class="mat-elevation-z8">
  <mat-paginator
    *ngIf="
      reportData.values.length > 0 &&
      reportData.totalItems > reportData.pageSize
    "
    [length]="reportData.totalItems"
    [pageSize]="reportData.pageSize"
    [pageIndex]="reportData.page"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
    (page)="setPagination($event)"
  >
  </mat-paginator>
      <div  *ngIf="successMessage"  class="success-message">
      <span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>
      {{ successMessage }}
      </div> 

  <table
    mat-table
    #viewTable
    [dataSource]="reportData.values"
    [class]="'repTable'"
    [style]="reportData.tableStyle"
    [class.enable-edit]="enabledEditReport"
  >
    <ng-container
      [matColumnDef]="column.name"
      *ngFor="let column of reportData.columns; let columnIndex = index"
    >
      <th mat-header-cell *matHeaderCellDef>
        <div [style]="column.style">
          {{ column.strTitle }}
          <div class="edit-icon" [style]="column.style">
            <mat-icon
              matTooltip="Edit column"
              [matTooltipShowDelay]="2000"
              [matMenuTriggerFor]="menu"
              (click)="onOpenMenu(column.name)">
              settings
            </mat-icon>
            <mat-menu #menu="matMenu">
              <ng-container *ngFor="let menu of columnMenu">
                <button *ngIf="!menu.disabled" mat-menu-item (click)="triggerMenuEvent(menu, column, columnIndex)">{{ menu.title }}</button>
              </ng-container>
            </mat-menu>
          </div>
        </div>
      </th>
      <ng-container *ngIf="column.strFormatType != 'ICON'">
        <td mat-cell *matCellDef="let entry; let rowIndex = index">
          <div [style]="column.style">
            {{ entry[columnIndex] | fn : formatCell : rowIndex : columnIndex }}
          </div>
        </td>
      </ng-container>
      <ng-container *ngIf="column.strFormatType == 'ICON'">
        <td mat-cell *matCellDef="let entry; let row">
          <div [style]="column.style">
            <img
              *ngIf="entry[column.index] != ''"
              (click)="startMaint(entry[column.index].row, column.index)"
              class="imgButton"
              width="16px"
              height="16px;"
              src="images/noImage.png"
              [name]="'row' + entry[column.index].mode"
            />
          </div>
        </td>
      </ng-container>
    </ng-container>
    <tr
      mat-header-row
      *matHeaderRowDef="reportData.showColumns"
      [style]="reportData.tableStyle"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: reportData.showColumns"
      [style]="reportData.tableStyle"
    ></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" *ngIf="showEmptyMessage" [attr.colspan]="reportData.showColumns.length" >
        <p class="empty-message">No data</p>
      </td>
    </tr>
  </table>

  <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"
      [diameter]="30">
    </mat-progress-spinner>
  </mat-card>
  <mat-paginator
    *ngIf="
      reportData.values.length > 0 &&
      reportData.totalItems > reportData.pageSize
    "
    [length]="reportData.totalItems"
    [pageSize]="reportData.pageSize"
    [pageIndex]="reportData.page"
    [hidePageSize]="true"
    [showFirstLastButtons]="true"
    (page)="setPagination($event)"
  >
  </mat-paginator>
</div>

