import { IReportColumn } from '../interfaces';

export const COLUMN_ATTRIBUTE: IReportColumn = {
    strTitle: "New Column",
    bGroup: false,
    strFormatType: "FLOT",
    iFormatValue: 2,
    strClass: "ReportNew_",
    iWidth: 45,
    iAlignType: 3,
    bIsLink: false,
    iSortIndex: 2,
    strType: "VALU",
    strDefinition: "",
    strLink: "",
    strLinkParams: "",
    style: {}
}