import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import { LOCALE_DATE_FORMAT } from 'src/app/shared/constants';

@Pipe({
  name: 'dateFormatPipe',
})
export class DateFormatPipe implements PipeTransform {
  public pattern: string;
  private patterns: object = LOCALE_DATE_FORMAT;
  private lang: string;

  constructor() {
    this.lang = navigator.language || 'en';
    this.pattern = this.patterns[this.lang];
  }

  transform(value: any, pattern?: string): string {
    pattern = pattern || this.pattern;
    return formatDate(value, pattern, this.lang);
  }
}
