export const USER_FORM_PARAM: object[] = [
    { 
        title: "Permissions", 
        id: 'arrPermissions'
    }, {
        title: "Locations", 
        id: 'arrLocations'
    }, {
        title: "Reports", 
        id: 'arrReports'
    }, {
        title: "Libraries", 
        id: 'arrLibraries'
    }, {
        title: "Groups", 
        id: 'arrGroups'
    }
];

export const USER_CONFIG_PARAM = {
    arrPermissions: [],
    arrLocations: [],
    arrReports: [],
    arrLibraries: []
};
